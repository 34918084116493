import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import CardGeneral from './card-general'
import { Check } from 'react-feather'

const SidebarHeader = styled.h4`
  margin-bottom: 0.5em;
`
const SidebarP = styled.p`
  color: #444;
`

const ContactInfo = () => (
  <StaticQuery
    query={graphql`
      query ContactInfoQuery {
        site {
          siteMetadata {
            companyInfo {
              phone
              email
              addressLine1
              addressLine2
              city
              state
              zip
              license
            }
          }
        }
      }
    `}
    render={data => {
      let { siteMetadata } = data.site
      return (
        <>
          <CardGeneral title={'About Us'}>
            {siteMetadata.companyInfo.license ? (
              <div>
                <SidebarHeader>Licensed &amp; Insured</SidebarHeader>
                <SidebarP>
                  We offer affordable yet professional DJ, sound and lighting
                  equipment services for any Sacramento event.
                </SidebarP>
                <SidebarP>
                  Whether it's for a wedding reception, birthday party,
                  anniversary or corporate event, you can count on us for
                  exceptional service every time.
                </SidebarP>
              </div>
            ) : null}
          </CardGeneral>
        </>
      )
    }}
  />
)

export default ContactInfo
